.logo-container {
    display: flex;
    justify-content: center;
    padding: 20px 0 20px 0;
}

.square-logo {
    height: 60px;
}

.eye-icon {
    width: 25px;
    height: 25px;
    cursor: pointer;
}