.authContainer {
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }

.authContainer .placeholder{
    font-size: 20px;
    line-height: 26px;
    color: var(--ion-color-zdark);
}

.ion-font-title, .auth-title-container
{
  text-align: center;
  justify-content: center;
}