.cursor-pointer {
    cursor: pointer;
}

ion-tab-bar
{
    --background: url('../images/Bitmap.png');
}

ion-menu-button
{
    --color: var(--ion-color-zdark);
}