ion-content ion-toolbar {
  --background: translucent;
}
ion-toolbar {
  /* --background: url('../../images/Bitmap.png') !important; */
  --background: url('../../images/Bitmap2.png') !important;
  background-image: url('../../images/Bitmap2.png') !important;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  --border-color: transparent;
}

ion-toolbar .toolbar-background
{
  --background: transparent !important;
}

.list-md {
  background-color: transparent;
}

.profile-folder-container {
  --ion-background-color:var(--ion-color-light-tint);
  overflow-x: auto;
}

.profile-background-img {
  margin-left: auto;
  margin-right: auto;
  background-size: auto;
  width: 80%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0.2;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  /* background-color: var(--ion-color-zdark-tint); */
}

.profile-container-background-color {
  background-color: var(--ion-color-light-tint);
  width: 100%;
  height: 100%;
  mix-blend-mode: multiply;
  position: absolute;
  /* z-index: 9; */
}

.profile-folder-content {
  background: transparent;
  z-index: 11;
  position: absolute;
  margin-left: var(--ion-margin, 16px) !important;
  margin-right: var(--ion-margin, 16px) !important;
  width: calc(100% - 32px) !important;
  width: -webkit-fill-available;
  width: -moz-available;
  width: fill-available;
}

.profile-background-notice {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  color: var(--ion-color-zdark);
  opacity: 0.6;
}

.back-btn-text {
  color: var(--ion-color-zdark);
}

.bitmap-texture
{
  --background: url('../../images/Bitmap.png') !important;
  background-image: url('../../images/Bitmap.png') !important;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  -webkit-box-shadow: 0px 6px 7px -5px rgba(0,0,0,0.5); 
  box-shadow: 0px 6px 7px -5px rgba(0,0,0,0.5);
}

ion-avatar,
ion-avatar > img{
    border-radius:0;
    /* height:12rem;
    width:12rem; */
}