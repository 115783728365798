.container {
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }

.dashed-bottom
{
  border-bottom: 1px dashed var(--ion-color-zdark-tint);
}

.bording-pass-unit
{
  border-radius: 10px;
  -webkit-box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.3); 
  box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.3);
  background-color: #ffffff;
}

.no-padding
{
  padding: 0;
}

.absolute-card
{
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 80%;
}

.qr-code
{
  margin-top: 0.5em;
}

.membership-id
{
  padding-top: 1em;
  padding-bottom: 1em;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.tinted-unit
{
  background-color: var(--ion-color-zsecondary)
}

.bording-pass-text-padding
{
  padding-bottom: 1em;
}

.member-card
{
  max-width: 350px;
}

@media only screen and (max-height: 568px)
{
  .membership-img
  {
    display: none;
  }
}