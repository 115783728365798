.container {
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  
.container table{
    display:inline-block
}

.error-font
{
    color: var(--ion-color-zdark);
}

table tbody tr {
    width: 100%;
}

.table-container {
    width: 100%;
    height: calc(100%);
}

.chart-table {
    width: 100%;
    height: 100%;
}

.tg {

}

.tg td{
    width: 25%;
    height: 25%;
    /* width: 100%;
    height: 100%; */
    border-color:black;
    border-style:solid;
    border-width:1px;
    font-family:Arial, sans-serif;
    font-size:12px;
    overflow:hidden;
    word-break:normal;
    position: relative;
    padding: 5px 0 5px 0 !important;

    /* width:150px; */
    /* height:100px; */
}

.tg .tg-0pky .tg-0lax{
    width: 25%;
    height: 25%;
    border-color:inherit;
    text-align:left;
    vertical-align:top;
    font-family: var(--ion-font-family);
}

.tg-0pky
{
    min-height: 90px;
    min-width: 78px;
}

ion-footer.ion-chart-footer
{
    --background: url('../../images/Bitmap2.png') !important;
    background-image: url('../../images/Bitmap2.png') !important;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    --border-color: transparent;
    height: 60px;
}

.time-switch {
    height: 30px;
    background-color: var(--ion-color-light);
    position: absolute;
    top: 0;
    width: 100%;
    mix-blend-mode: darken;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: var(--ion-font-family);
}

.chart-explain {
    height: 30px;
    background-color: var(--ion-color-zdark);
    color: #ffffff;
    position: absolute;
    bottom: 10px;
    right: 0px;
    left: 0px;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    /* font-size: 4vw; */
    border-radius: 5px;
    mix-blend-mode: darken;
    display: flex;
    justify-content: center;
    align-items: center;
}
.chart-explain:hover, .chart-explain:active
{
    background-color: var(--ion-color-zprimary-tint);
}

.chart-profile-display-wrapper {
    position: absolute;
    top: 4em;
    width: 100%;
    /* padding: 5%; */
}

.profile-name
{
    font-family: var(--ion-font-title);
    margin-left: auto;
    margin-right: auto;
    margin-top: 0;
    margin-bottom: 10px;
    text-align: center;
    color: var(--ion-color-zdark-shade);
    font-size: 1.2em;
}

.chart-profile-display-text {
    font-family: var(--ion-font-family);
    margin-right: 5px;
    margin-left: 5px;
    font-size: 1em;
    color: var(--ion-color-zdark);
    line-height: 1.4em;
    /* font-size: 4vw; */
}

/* =================================== */
/* ==      CHART UNIT STYLING       == */
/* =================================== */
.chart-unit-natal-palace {
    /* max-width: 40%; */
    position: absolute;
    /* margin-left: auto; */
    /* margin-right: auto; */
    bottom: 0;
    right: 1.2em;
    /* left: 0; */
    text-align: center;
    color: var(--ion-color-danger-shade);
    /* background-color: var(--ion-color-zprimary-tint); */
    /* color: #fff; */
    font-size: 1em;
    /* padding: 0 4px 0 4px; */
}

.chart-unit-decade-palace {
    /* max-width: 40%; */
    position: absolute;
    /* margin-left: auto; */
    /* margin-right: auto; */
    bottom: 1.01em;
    right: 1.2em;
    /* left: 0; */
    text-align: center;
    /* background-color: var(--ion-color-zmale-tint); */
    color: var(--ion-color-zdark-tint);
    font-size: 1em;
    /* padding: 0 4px 0 4px; */
}

.chart-unit-year-palace {
    /* max-width: 40%; */
    position: absolute;
    /* margin-left: auto; */
    /* margin-right: auto; */
    bottom: 2.03em;
    right: 1.2em;
    /* left: 0; */
    text-align: center;
    /* background-color: var(--ion-color-zdark-tint); */
    /* color: #fff; */
    font-size: 1em;
    color: var(--ion-color-zmale-tint);
    /* padding: 0 4px 0 4px; */
}

.chart-unit-ganzhi {
    width: 1em;
    writing-mode: vertical-rl;
    text-orientation: upright;
    position: absolute;
    bottom: 0;
    right: 0;
    font-size: 1em;
}

.chart-unit-main-stars {
    position: absolute;
    top: 2px;
    left: 0;
    padding: 0;
    margin: 0;
    line-height: 1.2;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    min-height: 50px;
    max-width: 100%;
    height: 50%;
}

.chart-unit-main-stars-extend
{
    position: absolute;
    top: 2px;
    left: 0;
    height: 60%;
    padding: 0;
    margin: 0;
    line-height: 1.2;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    min-height: 50px;
    max-width: 100%;
}

.star-brightness
{
    font-size: 0.7em;
    color: var(--ion-color-zdark-tint);
}
.chart-unit-support-stars {
    position: absolute;
    top: 2px;
    left: 0;
    height: 20%;
    padding: 0;
    margin: 0;
    line-height: 1.2;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    min-height: 50px;
    max-width: 100%;
}

.chart-unit-extra-stars {
    position: absolute;
    top: 55%;
    left: 0;
    height: 20%;
    padding: 0;
    margin: 0;
    /* line-height: 1.2; */
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-height: 40px;
    max-width: 100%;
    line-height: 1;
    max-width: 1em;
}

.north-star-unit {
    color: var(--ion-color-zprimary);
    writing-mode: vertical-lr;
    text-orientation: upright;
    left: 0;
    height: 100%;
    font-size: 1.1em;
    width: min-content;
    height: 100% !important;
    /* line-height: 1; */
    max-width: 1.1em;
    /* font-size: 3.5vw; */
}

.south-star-unit {
    color: var(--ion-color-zprimary);
    writing-mode: vertical-lr;
    text-orientation: upright;
    font-size: 1.1em;
    width: min-content;
    display: inline-block;
    /* line-height: 1; */
    height: 100% !important;
    max-width: 1.1em;
    /* display: flex; */
    /* font-size: 3.5vw; */

}

.support-star-unit {
    color: var(--ion-color-zdark);
    writing-mode: vertical-lr;
    text-orientation: upright;
    font-size: 1.1em;
    width: min-content;
    /* display: inline-block; */
    height: 100% !important;
    /* line-height: 1; */
    max-width: 1.1em;
    /* display: flex; */
    /* font-size: 3.5vw; */
}

.extra-star-unit {
    color: var(--ion-color-zmale-shade);
    writing-mode: vertical-lr;
    text-orientation: upright;
    font-size: 1.1em;
    width: min-content;
    /* display: inline-block; */
    /* line-height: 1; */
    /* height: 100% !important; */
    max-width: 1.1em;
    /* display: flex; */
    /* font-size: 3.5vw; */
}
.chart-unit-changing-sup-stars
{
    writing-mode: vertical-rl;
    position: absolute;
    top: 3.5em;
    right: 0;
    height: 20%;
    padding: 0;
    margin: 0;
    line-height: 1.2;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-end;
}

.chart-unit-xiaoxian-indicator
{
    writing-mode: vertical-rl;
    position: absolute;
    right: 0;
    text-align: right;
    bottom: 1.7em;
    font-size: 0.8em;
    background-color: var(--ion-color-zmale);
    padding: 1px 0 1px 0;
    color: #ffffff;
    border-radius: 2px;
}

.decade-sup-star-unit
{
    /* writing-mode: vertical-rl; */
    /* font-size: 0.7em; */
    /* position: absolute;
    right: 0;
    top: 3.5em; */
    color: var(--ion-color-zdark-tint);
}

.taisui-sup-star-unit
{
    /* writing-mode: vertical-rl;
    font-size: 0.7em; */
    /* position: absolute;
    right: 0;
    top: 3.5em; */
    color: var(--ion-color-zmale-tint);
}

.chart-unit-body-palace {
    /* writing-mode: vertical-rl; */
    /* text-orientation: upright; */
    position: absolute;
    font-size: 0.9em;
    /* padding-top: 4px;
    padding-bottom: 4px; */
    right: 2px;
    top: 4em;
    /* bottom: 20%; */
    background-color: var(--ion-color-zmale);
    color: #ffffff;
}

.chart-unit-decade-year
{
    position: absolute;
    left: 2.63em;
    width: 4em;
    bottom: 0;
    font-size: 0.8em;
    color: var(--ion-color-zdark-shade);
}
.chart-unit-decade-year.shrink
{
    position: absolute;
    left: 2.63em;
    width: 4em;
    bottom: 0;
    font-size: 0.8em;
    color: var(--ion-color-zdark-shade);
}

.chart-unit-changSheng {
    color: var(--ion-color-zdark-tint);
    
    position: absolute;
    bottom: 4em;
    right: 2px;
    text-align: right;
}

.chart-unit-boshi {
    position: absolute;
    bottom: 0em;
    left: 0;
    color: var(--ion-color-zdark-tint);
    font-size: 0.8em;
}

.chart-unit-jiangxing
{
    position: absolute;
    bottom: 1.1em;
    left: 0;
    color: var(--ion-color-zmale);
    font-size: 0.8em;

}
.chart-unit-jiangxing.decade-shift
{
    left: 2em;
    bottom: 0;
}
.chart-unit-suijian.decade-shift
{
    left: 2em;
    bottom: 1em;
}
.chart-unit-suijian
{
    position: absolute;
    bottom: 2.2em;
    left: 0;
    color: var(--ion-color-zmale);
    font-size: 0.8em;
}

.natal-morph
{
    font-size: 0.9em;
    background-color: var(--ion-color-danger-shade);
    color: #ffffff;
    border-radius: 2px;
    padding: 1px;
}

.decade-morph
{
    font-size: 0.9em;
    background-color: var(--ion-color-zdark-tint);
    color: #ffffff;
    border-radius: 2px;
    padding: 1px;
}

.taisui-morph
{
    font-size: 0.9em;
    background-color: var(--ion-color-zmale-tint);
    color: #ffffff;
    border-radius: 2px;
    padding: 1px;
}

.natal-morph-placeholder, .decade-morph-placeholder, .taisui-morph-placeholder
{
    font-size: 0.9em;
    background-color: transparent;
    color: transparent;
    border-radius: 2px;
    padding: 1px;
}

.chart-unit-decade-xiaoxian
{
    font-size: 0.8em;
    color: var(--ion-color-success-shade);
 
    max-width: 120px;

    right: 0;
    left: 0;
    bottom: 2.7em;
    margin-left: auto;

    text-align: end;
}

.option-btn-wrapper
{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    --background: url('../../images/Bitmap2.png') !important;
    background-image: url('../../images/Bitmap2.png') !important;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    --border-color: transparent;
}

.decade-unit-wrapper
{
    flex: 1;
    width: 100%;
    flex-wrap: wrap;
    flex-direction: row;
    font-size: 0.8em;
    display: flex;
    border: 1px solid var(--ion-color-zdark-tint);
    max-height: 30px;
    height: 50%;
}

.decade-unit-btn
{
    display: flex;
    flex-direction: column;
    width: 10%;
    font-size: 0.8em;
    cursor: pointer;
    border: 1px solid var(--ion-color-zdark-tint);
    justify-content: center;
    align-items: center;
    text-align: center;

    /* writing-mode: vertical-lr;
    text-orientation: upright; */
}
.decade-unit-btn.selected
{
    display: flex;
    flex-direction: column;
    width: 10%;
    font-size: 0.8em;
    cursor: pointer;
    border: 1px solid var(--ion-color-zdark-tint);
    justify-content: center;
    align-items: center;
    text-align: center;
    background: var(--ion-color-zdark-tint);
    color: #ffffff;
    /* writing-mode: vertical-lr;
    text-orientation: upright; */
}

.year-unit-wrapper
{
    flex: 1;
    width: 100%;
    flex-wrap: wrap;
    flex-direction: row;
    font-size: 0.8em;
    display: flex;
    bottom: 0;
    border: 1px solid var(--ion-color-zdark-tint);
    max-height: 30px;
    height: 50%;
}
.year-unit-btn
{
    display: flex;
    flex-direction: column;
    width: 10%;
    font-size: 0.8em;
    cursor: pointer;
    border: 1px solid var(--ion-color-zdark-tint);
    justify-content: center;
    align-items: center;
    text-align: center;
}
.year-unit-btn.selected
{
    display: flex;
    flex-direction: column;
    width: 10%;
    font-size: 0.8em;
    cursor: pointer;
    border: 1px solid var(--ion-color-zdark-tint);
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #ffffff;
    background: var(--ion-color-zmale-tint);
}

td.decade-unit-btn.selected
{
    background-color: var(--ion-color-zdark);
    color: #ffffff;
}

.taisui-year
{
    font-size: 0.8em;
}
.taisui-age
{
    font-size: 0.7em;
}


/*Small phone*/
@media (min-height: 500px) and (max-width: 375px) {
    .decade-sup-star-unit
    {
        /* writing-mode: vertical-rl;
        font-size: 1em; */
        /* position: absolute;
        right: 0;
        top: 4em; */
        color: var(--ion-color-zdark-tint);
    }

.chart-unit-xiaoxian-indicator {

  bottom: unset;

}
    .north-star-unit, .south-star-unit
    {
        font-size: 0.9em !important;
    }
    .extra-star-unit, .chart-unit-boshi, .chart-unit-suijian, .chart-unit-jiangxing, .chart-unit-body-palace,.support-star-unit
    {
        font-size: 0.9em !important;
    }
    .chart-unit-changSheng {
        font-size: smaller;
    }

    .chart-unit-body-palace
    {
        top: 2.5em;
    }
    .chart-unit-main-stars {
        height: 40%;
        font-size: 80%;
    }
    .chart-unit-decade-year
    {
        font-size: 0.9em;
        bottom: 1.7em;
        left: 2.5em;
    }
    .chart-unit-decade-year.shrink
    {
        font-size: 0.9em;
        bottom: 1.7em;
        left: 2.5em;
    }
    .chart-unit-main-stars-extend
    {
        height: 65%;
        font-size: 87%;
    }
}
/*Standsrd phone*/
@media (min-height: 601px) and (min-width: 376px) and (max-width: 699px) and (max-width:699px){
    .decade-sup-star-unit
    {
        /* writing-mode: vertical-rl;
        font-size: 1em; */
        /* position: absolute;
        right: 0;
        top: 4em; */
        color: var(--ion-color-zdark-tint);
    }
    .chart-unit-main-stars {
        height: 30%;
        font-size: x-small;
    }    
    .chart-unit-xiaoxian-indicator {

        bottom: unset;
      
      }
    .north-star-unit, .south-star-unit,.support-star-unit
    {
        font-size: 1.2em !important;
    }
    .extra-star-unit, .chart-unit-changSheng, .chart-unit-boshi, .chart-unit-suijian, .chart-unit-body-palace, .chart-unit-jiangxing
    {
        font-size: 1.1em !important;
    }
    .chart-unit-body-palace
    {
        top: 4em;
    }
    .chart-unit-main-stars {
        height: 30%;
        font-size: x-small;
    }
    .chart-unit-decade-year
    {
        font-size: 0.7em;
        bottom: 1.7em;
    }
    .chart-unit-main-stars-extend
    {
        height: 65%;
        font-size: 70%;
    }
}

/*Iphone pro*/
@media (min-height: 550px) and (min-width: 380px) and (max-width: 699px) {
    .decade-sup-star-unit
    {
        /* writing-mode: vertical-rl;
        font-size: 1em; */
        /* position: absolute;
        right: 0;
        top: 4em; */
        color: var(--ion-color-zdark-tint);
    }
    .north-star-unit, .south-star-unit,.support-star-unit
    {
        font-size: 1.2em !important;
    }.chart-unit-xiaoxian-indicator {

        bottom: unset;
      
      }
    .extra-star-unit, .chart-unit-changSheng, .chart-unit-boshi, .chart-unit-suijian, .chart-unit-body-palace, .chart-unit-jiangxing
    {
        font-size: 0.95em !important;
    }
    .chart-unit-body-palace
    {
        top: 2.5em;
    }
    .chart-unit-main-stars {
        height: 30%;
        font-size: 90%;
    }
    .chart-unit-decade-year
    {
        font-size: 0.9em;
        bottom: 1.7em;
        left: 3.5em;
    }
    .chart-unit-decade-year.shrink {
        font-size: 0.9em;
        bottom: 1.7em;
        left: 3.5em;

    }
    .chart-unit-main-stars-extend
    {
        height: 65%;
        font-size: x-small;
    }
}




/*iPad Pro*/
@media (min-height: 700px) and (min-width: 700px) {
    .chart-unit-main-stars
    {
        /* height: -webkit-fill-available; */
        height: 60%;
        text-orientation: upright;
        min-height: 20vh;
        font-size: larger;
    }
    .chart-unit-main-stars-extend
    {
        /* height: -webkit-fill-available; */
        height: 70%;
        text-orientation: upright;
        min-height: 20vh;
        font-size: larger;
    }
    .time-switch
    {
        height: 2.5em;
    }
    .profile-name
    {
        margin-top: 2em;
    }
    .north-star-unit, .south-star-unit, .support-star-unit,.extra-star-unit
    {
        /* height: 100% !important; */
    }
    .north-star-unit, .south-star-unit, .support-star-unit, .chart-unit-ganzhi, .chart-unit-natal-palace, .chart-unit-decade-palace, .chart-unit-year-palace, .chart-unit-xiaoxian-indicator
    {
        font-size: 1.1em !important;
    }
    .extra-star-unit, .chart-unit-changSheng, .chart-unit-boshi, .chart-unit-suijian, .chart-unit-body-palace, .chart-unit-jiangxing
    {
        font-size: 1em !important;
    }
    .chart-unit-decade-year
    {
        font-size: 1.1em !important;
        left: 40px;
    }
    .natal-morph
    {
        font-size: 1em !important;
    }
    .chart-unit-decade-xiaoxian
    {

        max-width: 200px;
    }
    .profile-name
    {
        font-size: 1.8em !important;
    }
    .chart-profile-display-text
    {
        font-size: 1.6em !important;
    }
    .time-switch, .chart-explain, .taisui-year, .taisui-age, .decade-unit-btn, .decade-sup-star-unit, .taisui-sup-star-unit
    {
        font-size: 1.4em !important;
    }
    .decade-unit-btn, .decade-unit-btn.selected, .year-unit-btn, .year-unit-btn.selected
    {
        flex-direction: row;
    }
}
