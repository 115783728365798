ion-list-header.ziwei-accordion-header {
    --background: #ffffff;
    color: var(--ion-color-zdark);
    font-size: 20px;
    /* padding: 0; */
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 30px;
    border-bottom: 2px solid var(--ion-color-zdark);
}

ion-list-header.ziwei-accordion-header.active {
    --background: var(--ion-color-zdark);
    color: var(--ion-color-light);
    font-size: 20px;
}
ion-label.accordion-label-begin {
    /* padding: 0; */
    text-align: left;
    margin: 0;
}
ion-label.accordion-label-end {
    display: flex;
    margin-top: 0;
    margin-bottom: 0;
    /* padding: 0; */
    /* margin: 0; */
}

ion-item.accordion-header-icon {
    --background: var(--ion-color-light);
}
ion-item.accordion-header-icon.active {
    --background: var(--ion-color-zdark);
}

.accordion-header-title {
    font-size: 1em !important;
}

.accordion-icon
{
    width: 20px;
    height: 20px;
}

.ion-accordion-item {
    --ion-background-color: white;
}

.accordion-item.active {
    opacity: 1;
    transition: 0.6s;
}

.accordion-item.no-active {
    opacity: 0;
    display: none;
}

.accordion-edit-btn, 
.accordion-remove-btn
{
    background-color: var(--ion-color-light);
    color: var(--ion-color-zdark);
    width: 60px;
    height: 30px;
    border-radius: 5px;
    margin: 5px 0 5px 0;
}

.arrow-down {
    right: 20px;
    position: absolute;
    z-index: 999;
    width: 0; 
    height: 0; 
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid var(--ion-color-zdark);
    margin-top: -1px;
}

.zmodal {
    overflow-y: auto;
}

.sc-ion-modal-md-h {
    overflow: auto !important;
    --overflow: auto;
}

.ion-page {
    overflow: auto !important;
}

.palace-content-wrapper
{
    background-color: #ffffff;
}
ion-fab.main-house-fab
{
    position: fixed;
    top: 4em;
}
ion-fab-button.main-house-button
{
    --background: #50405E;
}


/* Date time picker */

.calendar-type-btn
{
    padding: 10px;
    border-radius: 5px;
    background: var(--ion-color-light);
}
.calendar-type-btn.selected
{
    color: #ffffff;
    background: var(--ion-color-zdark);
}
.calendar-type-btn.lunar
{
    margin-left: 10px;
}

.datetime-input-unit
{
    width: 100%;
    height: 100%;
    border: transparent;
    margin-top: 0.5em;
    color: transparent;
    background: transparent;
    position: absolute;
    z-index: 999;
}

.leapmonth-checkbox
{
    margin-left: 10px;
}

/* The container */
.leapmonth-checkbox {
    /* display: block; */
    position: relative;
    padding-left: 2em;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 1em;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    /* top: 0;
    bottom: 0; */
  }
  
  /* Hide the browser's default checkbox */
  .leapmonth-checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: -0.3em;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: var(--ion-color-light);
    border-radius: 5px;
  }
  
  /* On mouse-over, add a grey background color */
  .leapmonth-checkbox:hover input ~ .checkmark {
    background-color: var(--ion-color-light);
  }
  
  /* When the checkbox is checked, add a blue background */
  .leapmonth-checkbox input:checked ~ .checkmark {
    background-color: var(--ion-color-zdark);
  }
  
  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the checkmark when checked */
  .leapmonth-checkbox input:checked ~ .checkmark:after {
    display: block;
  }
  
  /* Style the checkmark/indicator */
  .leapmonth-checkbox .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }