.landing-page {
    background-image: url('../images/landing-wide.png');
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-position: center center;
    /* background: cadetblue; */
    height: 100%;
}

ion-toolbar {
    --background: #FFF8F2;
}

.primary-text {
    color: #F23D64;
}