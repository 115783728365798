ion-content ion-toolbar {
  --background: translucent;
}
ion-toolbar {
  --background: #FFF8F2;
}

.chat-row {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 5px 10px 5px 10px;
  /* margin-left: 10px; */
}

.user-row
{
  justify-content: flex-end;
  align-items: flex-end;
}

.chat-bubble {
  background-color: var(--ion-color-zsuccess);
  width: fit-content;
  padding: 5px;
  border-radius: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.user-bubble {
  background-color: var(--ion-color-zdark);
  max-width: 60%;
  padding: 5px;
  border-radius: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
  color: #ffffff;
  text-align: end;
  padding-right: 20px;
  padding-left: 20px;
}

.system-bubble {
  background-color: var(--ion-color-light-tint);
  max-width: 60%;
  padding: 5px;
  border-radius: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
  min-height: 40px;
  display: flex;
  padding-left: 20px;
  padding-right: 20px;
  /* justify-content: center; */
  align-items: center;
}

.chat-bubble-option-bubble {
  border-radius: 10px;
  background-color: white;
  --background: var(--ion-color-zdark);
  padding: 5px;
  cursor: pointer;
  margin-top: 5px;
}


.ball-pulse-sync > div, .ball-beat > div
{
  width: 8px !important;
  height: 8px !important;
}